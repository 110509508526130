import "./style.css"
export function AboutMe() {
    return (
        <section className="aboutme" id="aboutme">
            <div className="container">
                <div className="description">
                    <h2>
                        SOBRE MIM
                    </h2>

                    <p>
                        Meu nome é Bianca Daiana Krüger e sou apaixonada pelo mundo da moda desde sempre.
                        Comecei minha jornada como costureira em 2004 e ao longo desses anos, adquiri uma bagagem de conhecimento
                        e habilidades que me permitem entregar resultados incríveis para meus clientes.
                    </p>
                    <br />
                    <p>
                        Formei-me em Design de Moda pelo Instituto Federal Catarinense em 2022,
                        o que me proporcionou uma base sólida para explorar toda minha criatividade e paixão pelo universo fashion.
                        E como eu adoro colocar a mão na massa (ou melhor, na máquina de costura!), me especializei em diversos aspectos desse ramo.
                    </p>
                    <br />
                    <p>
                        Tenho ampla experiência em todas as máquinas de costura, o que me permite criar peças com precisão e qualidade.
                        Além disso, possuo habilidades em modelagem, inspeção e corte de peças, garantindo um acabamento impecável em cada projeto que realizo.
                        E para agregar, já tive a oportunidade de contribuir como membro da CIPA e atuar como brigadista, o que demonstra minha dedicação e
                        preocupação com a segurança no ambiente de trabalho.
                    </p>
                    <br />
                    <p>
                        Além de ser uma profissional apaixonada pela moda, também sou uma defensora do meio ambiente.
                        Acredito que é nosso dever cuidar e preservar o planeta enquanto buscamos a beleza e a expressão através da moda.
                        Por isso, busco sempre utilizar práticas sustentáveis em meu trabalho, como o uso de materiais reciclados, tecidos orgânicos e técnicas de upcycling.
                        A moda pode ser uma forma de expressão criativa, mas também uma maneira de impactar positivamente o mundo ao nosso redor.
                    </p>
                    <br />
                    <p>
                        Uma das minhas maiores conquistas foi a abertura do meu próprio ateliê.
                        Lá, ofereço uma gama de serviços personalizados para meus clientes. Desde consertos e customizações de roupas
                        até a criação de peças sob medida, estou pronta para atender todas as necessidades fashion de cada pessoa.
                    </p>
                    <br />
                    <p>
                        E acreditem em mim, a moda não tem fronteiras! Além de criar peças incríveis para os seres humanos,
                        estendo minha criatividade e dedicação para nossos amados pets. Afinal, eles também merecem um toque de estilo e conforto em suas vidas.
                        É por isso que desenvolvo roupas especiais para os bichinhos de estimação, garantindo que eles estejam sempre na moda e se sintam ainda mais especiais.
                        Seja um cachorrinho fashionista ou um gatinho com estilo, estou aqui para vestir todos os peludos com muito carinho e bom gosto.
                    </p>
                    <br />
                    <p>
                        Além disso, já desenvolvi uniformes diferenciados para empresas e até roupas adaptadas para pessoas com necessidades especiais.
                        Afinal, a moda deve ser inclusiva e para todos!
                    </p>
                    <br />
                    <img
                        src="./bianca_logo.png"
                        alt=""
                        width="250px"
                    />
                </div>
                <img
                    src="./bianca_hero.jpg"
                    alt=""
                    width="500px"
                />
            </div>
        </section>
    )
}