
import { useState } from "react";
import "./style.css";
export function Header() {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };
    return (
        <header>
            <div className="container">
                <img src="./bianca_logo.png" alt="Logo Bianca" />
                <div className={`menu-section ${showMenu ? "on" : ""}`}>
                    <div className="menu-toggle" onClick={toggleMenu}>
                        <div className="one"></div>
                        <div className="two"></div>
                        <div className="three"></div>
                    </div>
                    <nav>
                        <ul>
                            <li>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
                                <a href="#" onClick={toggleMenu}>Home</a>
                            </li>
                            <li>
                                <a href="#aboutme" onClick={toggleMenu}>Sobre</a>
                            </li>
                            <li>
                                <a href="#projects" onClick={toggleMenu}>Projetos</a>
                            </li>
                            <li>
                                <a href="#studio" onClick={toggleMenu}>Ateliê</a>
                            </li>
                            <li>
                                <a href="#contact" onClick={toggleMenu}>Contato</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>

    )
}