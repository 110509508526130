import { SocialMedia } from "../socialMedia/SocialMedia";
import "./style.css";

export function Contact() {
    return (
        <section className="contact" id="contact">
            <div className="container">
                <h1>ENTRE EM CONTATO</h1>
                <p>Gostou do meu trabalho? Vamos conversar e criar peças únicas e personalizadas, do jeitinho que você sempre quis!</p>
                <p>Me siga nas redes sociais para acompanhar as novidades!</p>
                <SocialMedia />
            </div>
        </section>
    )
}