import { Header } from "./components/header/Header";
import { Hero } from "./components/hero/Hero";
import { AboutMe } from "./components/about/AboutMe";
import { Projects } from "./components/projects/Projects";
import { Footer } from "./components/footer/Footer";
import { Contact } from "./components/contact/Contact";
import { Studio } from "./components/studio/Studio";


export function App() {
  return (
    <>
      <div className="App">
        <Header />
        <Hero />
        <AboutMe />
        <Projects />
        <Studio />
        <Contact />
        <Footer />
      </div>
    </>
  );
}
