import { Card } from "./cards/Card"
import "./style.css"
export function Projects() {
    return (
        <section className="projects" id="projects">
            <div className="container">
                <h1>Projetos</h1>
                <h3>
                    Conheça um pouco do meu trabalho!
                </h3>
                <div className="galeria">
                    {/* <Card
                        title="TCC"
                        description="Descrição do projeto."
                        url="#"
                        image="./01.png" /> *4 */}

                    <h1>Em breve...</h1>

                </div>
            </div>
        </section>

    )
}