import "./style.css";

export function Hero() {
    return (
        <section className="hero" id="hero">
            <div className="container">
                <h2>
                    Designer de Moda
                </h2>
                <p>
                    Transformando ideias em realidade, com excelência e exclusividade!
                </p>
            </div>
        </section>
    )
}