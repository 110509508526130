import "./style.css"
import { RiArrowRightDoubleLine } from "react-icons/ri";
export function Studio() {
    return (
        <section className="studio" id="studio">
            <div className="container">
                <img src="./atelie.png" alt="Ateliê de costura" />
                <h3>Confira os serviços disponíveis</h3>
                <div className="services">
                    <div className="service conserto">
                        <h4>Conserto de roupas</h4>
                        <p>
                            O serviço especializado de conserto de roupas, é pensado para valorizar cada peça que você possui.
                        </p>
                        <br />
                        <p>
                            Uma das vantagens de consertar suas roupas é ser uma opção sustentável,
                            evitando o descarte prematuro de peças que ainda podem ser usadas.
                        </p>
                        <br />
                        <p>
                            Além disso, você economiza dinheiro, pois reparar uma peça é geralmente mais acessível do que comprar uma nova.
                        </p>
                        <br />
                        <p>
                            E não podemos esquecer a questão emocional, afinal, aquela peça que tem um valor sentimental merece uma nova chance.
                        </p>
                        <br />
                        <p>
                            Por isso, realize os reparos necessários, garantindo que suas roupas voltem a ter um caimento perfeito e sejam usadas por
                            muito mais tempo.
                        </p>
                        <h5>Serviços oferecidos:</h5>
                        <ul>
                            <li><RiArrowRightDoubleLine className="iconList" />Troca de zíper</li>
                            <li><RiArrowRightDoubleLine className="iconList" />Pregar botão</li>
                            <li><RiArrowRightDoubleLine className="iconList" />Fazer baínha</li>
                            <li><RiArrowRightDoubleLine className="iconList" />Ajustes e reformas de roupas</li>
                            <li><RiArrowRightDoubleLine className="iconList" />Conserto de rasgos e descosturados</li>
                            <li><RiArrowRightDoubleLine className="iconList" />Customização e personalização de roupas</li>
                            <li><RiArrowRightDoubleLine className="iconList" />E muito mais..</li>
                        </ul>
                    </div>
                    <div className="service sobmedida">
                        <h4>Confecção de roupas</h4>
                        <p>
                            O serviço exclusivo de confecção de roupas, permite a personalização da peça de acordo com cada cliente.
                        </p>
                        <br />
                        <p>
                            Ao optar por uma roupa feita especialmente para você, você está contribuindo para a redução do consumo excessivo e
                            evitando a produção em massa, além de colaborar com a preservação do meio ambiente.
                        </p>
                        <br />
                        <p>
                            Este serviço inclui um acompanhamento durante todo o processo, desde a seleção de tecidos até os detalhes finais, combinando
                            suas preferências, medidas e estilo pessoal para criar peças únicas, transmitindo confiança e elegância em todas as ocasiões.
                        </p>
                        <h5>Serviços oferecidos:</h5>
                        <ul>
                            <li><RiArrowRightDoubleLine className="iconList" />Roupas sob medida</li>
                            <li><RiArrowRightDoubleLine className="iconList" />Uniformes</li>
                            <li><RiArrowRightDoubleLine className="iconList" />Roupas para pet</li>
                            <li><RiArrowRightDoubleLine className="iconList" />Roupas adulto e infantil</li>
                            <li><RiArrowRightDoubleLine className="iconList" />Roupas para portadores de necessidades especiais</li>
                            <li><RiArrowRightDoubleLine className="iconList" />E muito mais..</li>

                        </ul>
                    </div>
                    <div className="service tapetes">
                        <h4>Tapetes frufru</h4>
                        <p>
                            A criação de tapetes frufru em meu ateliê é uma opção sustentável para adicionar um toque de charme e personalidade a qualquer ambiente.
                        </p>
                        <br />
                        <p>
                            São confeccionados a partir de sobras de frisos e materiais reutilizados, transformando-os em lindas peças decorativas.
                        </p>
                        <br />
                        <p>
                            Além de serem ecologicamente corretos, esses tapetes trazem consigo uma história de reaproveitamento, tornando cada um deles exclusivo e especial.
                        </p>
                        <br />
                        <p>
                            Ao optar por um tapete frufru, você está contribuindo para a redução do desperdício e para a preservação do meio ambiente.
                        </p>
                        <br />
                        <p>
                            Seja para decorar sua sala, quarto, escritório ou qualquer outro ambiente, os tapetes frufru trazem uma sensação de aconchego e originalidade.
                        </p>
                        <h5>Opções disponíveis:</h5>
                        <ul>
                            <li><RiArrowRightDoubleLine className="iconList" />Tapete para porta</li>
                            <li><RiArrowRightDoubleLine className="iconList" />Jogo para banheiro</li>
                            <li><RiArrowRightDoubleLine className="iconList" />Jogo para cozinha</li>
                            <li><RiArrowRightDoubleLine className="iconList" />Passadeira</li>
                            <li><RiArrowRightDoubleLine className="iconList" />E muito mais..</li>
                        </ul>
                    </div>
                </div>
            </div>

        </section>
    )
}