import "./style.css"
export function Footer() {
    return (
        <footer>
            <div className="container">
                &copy; 2023 MoonLab Tecnologia. Todos os direitos
                reservados.
            </div>
        </footer>
    )

}